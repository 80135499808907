import instance from "./Request";

export const getBranches = async (userid) => {
  const request = await instance();
  let data = await request.post(`/branches/${userid==undefined?0:userid}`).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const changeTakeOut = async (branch, value) => {
  const request = await instance();
  let data = await request
    .post("/branches/take-out", { id: branch, take_out: value })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const changeDelivery = async (branch, value) => {
  const request = await instance();
  let data = await request
    .post("/branches/delivery", { id: branch, delivery: value })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getDepartments = async () => {
  const request = await instance("SV");
  let data = await request.get("/main/departments").catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const getSchedule = async () => {
  const request = await instance();
  let data = await request.get("/main/info-timedelivery").catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const updateSchedule = async (open, close) => {
  const request = await instance();
  let data = await request
    .post("/main/info-timedelivery", {
      open,
      close,
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};



export const newBranch = async (branch) => {
  const request = await instance();
  let data = await request.post("/branches/create", branch).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const editBranch = async (branch) => {
  const request = await instance();
  let data = await request.post("/branches/update", branch).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const deleteBranch = async (id) => {
  const request = await instance();
  let data = await request.post("/branches/delete", { id }).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const getBranch = async (branch) => {
  const request = await instance();
  let data = await request.get(`/branch/${branch}`).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const uploadImage = async (image) => {
  const request = await instance(true);
  let data = await request.post("/branches/image", image).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

